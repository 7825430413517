import { mapGetters } from 'vuex';
export default {
  name: 'Error',
  data() {
    return {
      locationPath: '/login',
      textBtn: this.$t('global.back')
    };
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed'
    })
  },
  mounted() {
    if (this.isAuthed) {
      this.locationPath = this.$router.options.history.state.back || '/home';
    } else {
      this.locationPath = '/login';
    }
  },
  methods: {}
};