export default {
  namespaced: true,
  state: {
    data: null,
    language: 'zh-Hant',
    countryCode: null,
    loading: false
  },
  getters: {
    isAuthed: (state) => {
      return !!state.data
    },
    me: (state) => {
      return state.data || {}
    },
    loading: (state) => {
      return state.loading
    },
    language: (state) => {
      return state.language
    },
    countryCode: (state) => {
      return state.countryCode
    }
  },
  mutations: {
    startLoading: (state) => {
      state.loading = true
    },
    stopLoading: (state) => {
      state.loading = false
    },
    setLanguage: (state, language) => {
      state.language = language
    },
    setData: (state, data) => {
      state.data = data
    },
    clearData: (state) => {
      state.data = null
    },
    updateData: (state, data) => {
      state.data = {
        ...state.data,
        ...data
      }
    },
    setCountryCode: (state, country) => {
      state.countryCode = country
    }
  },
  actions: {
    login: ({ commit }, { token, user }) => {
      commit('startLoading')
      window.localStorage.setItem('loginToken', token)
      commit('setData', user)
      commit('stopLoading')
    },
    logout: ({ commit }) => {
      commit('startLoading')
      commit('updateData', { is_guest: true })
      commit('stopLoading')
    },
    switchLanguage: ({ commit }, { language }) => {
      commit('setLanguage', language)
    },
    updateUser: ({ commit }, data) => {
      commit('updateData', data)
    },
    setCountry: ({ commit }, { country }) => {
      commit('setCountryCode', country)
    }
  }
}
