import { mapGetters } from 'vuex';
import { Announcement } from '@/api/api';
import { Swipe, SwipeItem } from 'vant';
export default {
  name: 'NoticeInfoModal',
  data() {
    return {
      list: [],
      show: false
    };
  },
  components: {
    Swipe,
    SwipeItem
  },
  computed: {
    ...mapGetters({
      language: 'auth/language'
    })
  },
  methods: {
    handleToPrev() {
      this.$refs.Swipe.prev();
    },
    handleToNext() {
      this.$refs.Swipe.next();
    },
    renderContent(content) {
      var _content, _this$language, _Object$values$, _Object$values;
      return (_content = content === null || content === void 0 ? void 0 : content[(_this$language = this.language) !== null && _this$language !== void 0 ? _this$language : 'en']) !== null && _content !== void 0 ? _content : content ? (_Object$values$ = (_Object$values = Object.values(content)) === null || _Object$values === void 0 ? void 0 : _Object$values[0]) !== null && _Object$values$ !== void 0 ? _Object$values$ : '' : '';
    },
    async getAnnouncement() {
      try {
        const {
          data,
          status
        } = await Announcement.announcement();
        if (status === 200 && (data === null || data === void 0 ? void 0 : data.length) > 0) {
          this.list = data;
          this.show = true;
        }
      } catch (err) {} finally {/* empty */}
    }
  },
  mounted() {
    this.getAnnouncement();
  }
};