import asyncComponent from '@/router/asyncComponent'
import Error from '@/pages/error/error'

export default [
  {
    path: '/login',
    name: 'Login',
    component: asyncComponent({
      loader: () => import('@/pages/auth/login')
    })
  },
  {
    path: '/register',
    name: 'Register',
    component: asyncComponent({
      loader: () => import('@/pages/auth/register')
    })
  },
  {
    path: '/verify',
    name: 'Verify',
    component: asyncComponent({
      loader: () => import('@/pages/auth/verify')
    })
  },
  {
    path: '/home',
    alias: '/',
    name: 'home',
    component: asyncComponent({
      loader: () => import('@/pages/home/home')
    })
  },
  {
    path: '/map',
    name: 'Map',
    component: asyncComponent({
      loader: () => import('@/pages/map/map')
    })
  },
  {
    path: '/scan/:id?',
    name: 'Scan',
    component: asyncComponent({
      loader: () => import('@/pages/qrscan/qrscan')
    })
  },
  {
    path: '/form',
    name: 'Form',
    component: asyncComponent({
      loader: () => import('@/pages/form/form')
    })
  },
  {
    path: '/profile',
    name: 'Profile',
    component: asyncComponent({
      loader: () => import('@/pages/profile/profile')
    })
  },
  {
    path: '/edit-profile',
    name: 'EditProfile',
    component: asyncComponent({
      loader: () => import('@/pages/editProfile/editProfile')
    })
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: asyncComponent({
      loader: () => import('@/pages/resetPassword/resetPassword')
    })
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: asyncComponent({
      loader: () => import('@/pages/transactions/transactions')
    })
  },
  {
    path: '/payment',
    name: 'Payment',
    component: asyncComponent({
      loader: () => import('@/pages/payment/payment')
    })
  },
  {
    path: '/order',
    name: 'Order',
    component: asyncComponent({
      loader: () => import('@/pages/order/order')
    })
  },
  {
    path: '/search',
    name: 'Search',
    component: asyncComponent({
      loader: () => import('@/pages/search/search')
    })
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: asyncComponent({
      loader: () => import('@/pages/notifications/notifications')
    })
  },
  {
    path: '/p/privacy',
    name: 'Privacy',
    component: asyncComponent({
      loader: () => import('@/pages/static/privacy')
    })
  },
  {
    path: '/p/terms',
    name: 'Terms',
    component: asyncComponent({
      loader: () => import('@/pages/static/terms')
    })
  },
  {
    path: '/p/about',
    name: 'About',
    component: asyncComponent({
      loader: () => import('@/pages/static/about')
    })
  },
  {
    path: '/p/contact',
    name: 'Contact',
    component: asyncComponent({
      loader: () => import('@/pages/static/contact')
    })
  },
  {
    path: '/:pathMatch(.*)*',
    component: Error
  }
]
