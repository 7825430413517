import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import { Auth, User } from '@/api/api';
import Splash from '@/components/splash/splash';
import * as Sentry from '@sentry/vue';
import { serverCountry } from '@/config/config';
import NoticeInfoModal from '@/components/noticeInfoModal/NoticeInfoModal';
import { ModalsContainer } from 'vue-final-modal';
export default {
  name: 'App',
  components: {
    Splash,
    NoticeInfoModal,
    ModalsContainer
  },
  data() {
    return {
      isLoading: true,
      questLogin: false
    };
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed',
      me: 'auth/me'
    })
  },
  async mounted() {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();
    this.getLocation();
    this.checkAuth();
  },
  methods: {
    getLocation() {
      this.$store.dispatch('auth/setCountry', {
        country: serverCountry
      });
    },
    async checkAuth() {
      const token = window.localStorage.getItem('loginToken');
      if (token) {
        Auth.setToken(token);
        try {
          const {
            data
          } = await User.me();
          this.$store.dispatch('auth/login', {
            token,
            user: {
              ...data
            }
          });
        } catch (e) {} finally {
          window.setTimeout(() => {
            this.isLoading = false;
          }, 250);
        }
      } else {
        this.isLoading = false;
      }
    },
    async guestLogin() {
      this.isLoading = true;
      this.questLogin = true;
      try {
        const {
          data
        } = await Auth.guest();
        Auth.setToken(data.token);
        const user = await User.me();
        this.$store.dispatch('auth/login', {
          token: data.token,
          user: {
            ...user.data
          }
        });
      } catch (e) {} finally {
        window.setTimeout(() => {
          this.isLoading = false;
        }, 250);
      }
    }
  },
  watch: {
    isLoading(val, old) {
      if (!val && old) {
        if (!this.isAuthed && !this.questLogin) {
          this.guestLogin();
        } else if (this.$route.fullPath === '/') {
          this.$router.push('/home');
          window.dataLayer.push({
            event: 'userData',
            user_id: this.me.id
          });
          Sentry.setUser({
            user_id: this.me.id
          });
        } else if (this.isAuthed) {
          window.dataLayer.push({
            event: 'userData',
            user_id: this.me.id
          });
          Sentry.setUser({
            user_id: this.me.id
          });
        }
      }
    }
  }
};