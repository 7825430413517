import { v4 as uuidv4 } from 'uuid'

export const Auth = {
  resetPassword: (oldPassword, newPassword, confirmPassword) => {
    return window.axios.post('/auth/update-password', {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword
    })
  },
  login: (phone, password) => {
    return window.axios.post('auth/login', {
      phone: phone.replace(/\s+/g, ''),
      password
    })
  },
  register: (name, email, password, code) => {
    return window.axios.post('auth/register', {
      name,
      email,
      password,
      invitation_code: code
    })
  },
  guest: () => {
    const deviceId = localStorage.getItem('deviceId') || uuidv4()

    localStorage.setItem('deviceId', deviceId)

    return window.axios.post('auth/guest', {
      device_id: `${deviceId}`
    })
  },
  logout: () => {
    return window.axios.post('auth/logout')
  },
  requestOTP: (phone) => {
    const deviceId = localStorage.getItem('deviceId') || uuidv4()

    localStorage.setItem('deviceId', deviceId)
    return window.axios.post('auth/request-otp', {
      phone: phone.replace(/\s+/g, ''),
      device: deviceId
    })
  },
  loginOTP: (phone, otp) => {
    return window.axios.post('auth/otp-login', {
      phone: phone.replace(/\s+/g, ''),
      otp
    })
  },
  setToken: (token) => {
    window.axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`
    }
  }
}

export const User = {
  me: () => {
    return window.axios.get('user')
  },
  updateMe: (data) => {
    return window.axios.post('user', data)
  },
  content: (data) => {
    return window.axios.get('content', {
      params: data
    })
  },
  ads: (data) => {
    return window.axios.get('advertisement', {
      params: data
    })
  },
  smartAds: (data) => {
    return window.axios.get('smart-advertisement', {
      params: data
    })
  },
  transaction: (data) => {
    return window.axios.get('transaction', {
      params: data
    })
  },
  location: () => {
    return window.axios.get('location')
  }
}

export const Template = {
  random: (count) => {
    return window.axios.get('template/random', {
      params: {
        count,
        version: 3
      }
    })
  },
  get: (id) => {
    return window.axios.get('template/' + id)
  },
  search: (query) => {
    return window.axios.post('search', {
      keyword: query
    })
  }
}

export const Screen = {
  all: (onlyOpening, onOnline, pos) => {
    return window.axios.get('screen', {
      params: {
        lat: pos?.lat,
        lng: pos?.lng,
        only_opening: 0,
        only_online: 0
      }
    })
  },
  getOne: (id) => {
    return window.axios.get('screen/' + id)
  },
  getList: (ids) => {
    return window.axios.get('screen/get-list', {
      params: {
        ids
      }
    })
  }
}

export const Ad = {
  create: (data) => {
    return window.axios.post('/advertisement/create', {
      ...data
    })
  },
  price: (data) => {
    return window.axios.post('/advertisement/price', {
      ...data
    })
  }
}

export const Notifications = {
  count: () => {
    return window.axios.get('/notifications/count')
  },
  paginate: (data) => {
    return window.axios.get('/notifications/all', {
      params: data
    })
  },
  readOne: (id) => {
    return window.axios.post('/notifications/read', {
      notification_id: id
    })
  },
  readAll: () => {
    return window.axios.post('/notifications/read')
  }
}

export const SmartAd = {
  create: (data) => {
    return window.axios.post('/smart-advertisement/create', {
      ...data
    })
  },
  price: (data) => {
    return window.axios.post('/smart-advertisement/price', {
      ...data
    })
  },
  refill: (id, amount) => {
    return window.axios.post('/smart-advertisement/refill', {
      id,
      amount
    })
  }
}

export const File = {
  upload: (file) => {
    const formData = new FormData()

    formData.append('file', file)
    return window.axios.post('/upload/file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}

export const Payment = {
  createIntent: (order, points, currency) => {
    return window.axios.post('/payment/create-payment-intent', {
      juker_points_to_purchase: points,
      currency: currency.toUpperCase(),
      order_ids: order
    })
  },
  createResellerIntent: (order, points, currency) => {
    return window.axios.post('/payment/create-reseller-order-payment-intent', {
      juker_points_to_purchase: points,
      currency: currency.toUpperCase(),
      order_ids: order
    })
  },
  createPayment: (data) => {
    return window.axios.post('/payment/payment-asia', data)
  },
  checkStatus: (intent) => {
    return window.axios.get('payment/check-status', {
      params: {
        payment_intent: intent
      }
    })
  }
}

export const Order = {
  create: (data) => {
    return window.axios.post('order', data)
  },
  createReseller: (data) => {
    return window.axios.post('reseller-order', data)
  },
  completed: (data) => {
    return window.axios.get('order/completed', {
      params: data
    })
  },
  notCompleted: (data) => {
    return window.axios.get('order/not-completed', {
      params: data
    })
  }
}

export const Announcement = {
  announcement: () => {
    return window.axios.get('announcement')
  }
}
export const Goods = {
  search: (data) => {
    return window.axios.get('/ad-goods/search', {
      params: data
    })
  }
}
