export const endpoint = process.env.VUE_APP_API_DOMAIN
export const stripeKey = process.env.VUE_APP_STRIPE_KEY
export const environment = process.env.VUE_APP_ENV
export const serverCountry = process.env.VUE_APP_SERVER_COUNTRY || 'HK'
export const isChineseBuild = serverCountry === 'CN'

export const adStatusText = {
  1: 'status.submitted',
  4: 'status.scheduled',
  5: 'status.published',
  6: 'status.rejected',
  7: 'status.auction_failed',
  8: 'status.canceled',
  9: 'status.expired',
  10: 'status.submitted'
}

export const orderStatusText = {
  1: 'order_status.created',
  2: 'order_status.paid',
  3: 'order_status.not_used',
  4: 'order_status.canceled',
  5: 'order_status.completed',
  6: 'order_status.refunded'
}

export const adStatus = {
  submitted: 1,
  scheduled: 4,
  published: 5,
  rejected: 6,
  auction_failed: 7,
  canceled: 8,
  expired: 9,
  bid_win: 10
}

export const orderStatus = {
  created: 1,
  paid: 2,
  not_used: 3,
  canceled: 4,
  completed: 5,
  refunded: 6
}

export const contentStatus = {
  submitted: 1,
  approved: 2,
  processed: 3,
  rejected: 4,
  process_failed: 5
}

export const contentStatusText = {
  [contentStatus.submitted]: 'content_status.submitted',
  [contentStatus.approved]: 'content_status.approved',
  [contentStatus.processed]: 'content_status.processed',
  [contentStatus.rejected]: 'content_status.rejected',
  [contentStatus.process_failed]: 'content_status.process_failed'
}

export const products = {
  '90cf66f9-48cc-42be-8526-441da7a51501': {
    id: '90cf66f9-48cc-42be-8526-441da7a51501',
    price: 12000,
    features: [
      {
        key: 'product.feat1',
        val: 1
      },
      {
        key: 'product.feat2',
        val: 1200
      }
    ]
  },
  'a67c3642-1eaf-42f4-b081-4d6d72df7c94': {
    id: 'a67c3642-1eaf-42f4-b081-4d6d72df7c94',
    price: 60000,
    features: [
      {
        key: 'product.feat1',
        val: 5
      },
      {
        key: 'product.feat2',
        val: 6000
      }
    ]
  },
  'd9cddc45-ccde-4ce5-87dd-d3977ad86ae6': {
    id: 'd9cddc45-ccde-4ce5-87dd-d3977ad86ae6',
    price: 120000,
    features: [
      {
        key: 'product.feat1',
        val: 10
      },
      {
        key: 'product.feat2',
        val: 12000
      }
    ]
  },
  // for test
  'f5e6b616-25fc-424b-a90f-43dc5620ea11': {
    id: 'f5e6b616-25fc-424b-a90f-43dc5620ea11',
    price: 1,
    features: [
      {
        key: 'product.feat1',
        val: 1
      },
      {
        key: 'product.feat2',
        val: 0
      }
    ]
  }
}

export const AMapCountries = [
  'CN'
]

export const ScreenStatus = {
  online: 1,
  offline: 2
}

export const ScreenStatusText = {
  [ScreenStatus.online]: 'status.online',
  [ScreenStatus.offline]: 'status.offline'
}

export const TemplateType = {
  photo: 1,
  video: 2,
  faceSwap: 3,
  aiImage: 4,
  live: 5
}

export const TransactionType = {
  debit: 1,
  credit: 2
}

export const TransactionTypeText = {
  [TransactionType.debit]: 'transactions.label_spent',
  [TransactionType.credit]: 'transactions.label_topUp'
}

export const NotificationType = {
  adPublished: 'AdvertisementPublished',
  adScheduled: 'AdvertisementScheduled',
  contentRejected: 'ContentRejected',
  contentApproved: 'ContentApproved'
}

export const NotificationDescription = {
  [NotificationType.adPublished]: 'notification_description.advertisement_published',
  [NotificationType.adScheduled]: 'notification_description.advertisement_scheduled',
  [NotificationType.contentRejected]: 'notification_description.content_rejected',
  [NotificationType.contentApproved]: 'notification_description.content_approved'
}
