import { createI18n } from 'vue-i18n'
import { isChineseBuild } from '@/config/config'

import en from './locales/en.json'
import hk from './locales/zh-HK.json'
import cn from './locales/zh-CN.json'

let messages
let locale
let fallbackLocale

if (isChineseBuild) {
  locale = 'zh-Hans'
  fallbackLocale = 'zh-Hans'
  messages = {
    'zh-Hans': cn
  }
} else {
  locale = 'zh-Hant'
  fallbackLocale = 'en'
  messages = {
    en,
    'zh-Hant': hk
  }
}

export default createI18n({
  locale, // set locale
  fallbackLocale, // set fallback locale
  silentTranslationWarn: true,
  messages
})
