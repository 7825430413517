import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueFinalModal from 'vue-final-modal'
import Toaster from '@meforma/vue-toaster'
import { createHead, Head } from '@vueuse/head'
import axios from 'axios'
import { endpoint, environment, isChineseBuild } from '@/config/config'
import i18n from './config/i18n/i18n'
import mitt from 'mitt'
import './registerServiceWorker'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css

window.axios = axios.create({
  baseURL: endpoint,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

window.axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response?.status === 401) {
    const pages = ['login', 'auth/logout']
    if (new RegExp(pages.join('|') + '$').test(error.response.config.url)) {
      return Promise.reject(error)
    } else {
      localStorage.clear()
      sessionStorage.clear()
    }
  }

  return Promise.reject(error)
})

const appVue = createApp(App)
const headmanager = createHead()

const emitter = mitt()

appVue.config.globalProperties.$isChineseBuild = isChineseBuild
appVue.config.globalProperties.emitter = emitter
appVue.config.globalProperties.$filters = {
}
appVue.directive('plaintext', {
  created (el, binding, vnode) {
    el.innerHTML = el.innerText
  },
  updated (el, binding, vnode) {
    if (vnode.children[0]?.el?.innerText) {
      el.innerHTML = vnode.children[0]?.el.innerText
    }
  }
})

Sentry.init({
  appVue,
  dsn: 'https://c04a3bcccb494b7190d85bdf35a6170d@o455698.ingest.sentry.io/6446277',
  environment,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost:8000', 'api.juker3.com', 'api.juker3.cn']
    })
  ],
  denyUrls: [
    /localhost:8080/i
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})
Sentry.attachErrorHandler(appVue, { logErrors: true })

appVue.component('HeadTag', Head)

appVue.use(vue3videoPlay)
appVue.use(i18n)
appVue.use(Toaster)
appVue.use(headmanager)
appVue.use(router)
appVue.use(store)
appVue.use(VueFinalModal())
appVue.mount('#app')
